<template>
    <div class="scheme-info">

        <div class="info-left">

            <form-box label="搭配标题">
                <a-input v-model="params.title" :maxLength="20" placeholder="请输入不超过20字的标题" type="text" />
                <div class="num">{{ params.title.length }}/20</div>
            </form-box>

            <form-box label="搭配说明">
                <a-input v-model="params.summary" :maxLength="50" placeholder="请输入不超过50字的说明" type="text" />
                <div class="num">{{ params.summary.length }}/50</div>
            </form-box>

            <form-box label="适宜性别">
                <div :class="params.gender == 2 ? 'gender active' : 'gender'" @click="params.gender = 2">
                    <img src="/assets/images/deck/info-active-nv.png" v-if="params.gender == 2">
                    <img src="/assets/images/deck/info-nv.png" v-else>
                    <span>女士</span>
                </div>
                <div :class="params.gender == 1 ? 'gender active' : 'gender'" @click="params.gender = 1">
                    <img src="/assets/images/deck/info-active-nan.png" v-if="params.gender == 1">
                    <img src="/assets/images/deck/info-nan.png" v-else>
                    <span>男士</span>
                </div>
            </form-box>

            <style-box :label="item.label" v-for="item in list" :key="item.label">
                <div class="list">
                    <div class="item" v-for="child in item.list" :key="child.id" @click="styleChange(child)">
                        <div class="bg">
                            <img :src="child.thumb">
                            <img class="active" src="/assets/images/deck/style-select.png" v-if="child.active">
                        </div>
                        <div class="name">{{ child.name.split('style')[0] }}</div>
                    </div>
                </div>
            </style-box>

            <div class="btn-box">
                <div class="cancel cursor" @click="back()">取消</div>
                <div class="submit cursor" @click="submitScheme()">提交</div>
            </div>
        </div>

        <div class="info-right">
            <div class="scheme-img">
                <img :src="image">
            </div>
            <div class="scheme-label">搭配缩略图</div>
        </div>

        <scheme-qrcode :type="schemeType" :scheme-id="detailId" v-model="showDetailQrcode"></scheme-qrcode>
    </div>
</template>

<script>
import api from "@/repo/api";
import FormBox from '../Com/FormBox.vue';
import StyleBox from "../Com/StyleBox.vue";
import SchemeQrcode from "@/components/SchemeQrcode";

export default {
    components: { FormBox, StyleBox, SchemeQrcode },
    data() {
        return {
            hideMenu: true,
            showDetailQrcode: false,
            detailId: 0,
            image: '',
            schemeType: 'shop',
            list1: [],
            list2: [],
            list3: [],
            list4: [],
            params: {
                title: '',
                summary: '',
                gender: 2,
            },
        }
    },
    computed: {
        list() {
            let arr1 = [];
            let arr2 = [];
            let arr3 = [];
            let arr4 = [];

            this.list1.forEach((item) => {
                if (item.gender == this.params.gender) {
                    arr1.push(item);
                }
            })

            this.list2.forEach((item) => {
                if (item.gender == this.params.gender || item.gender == 0) {
                    if (this.params.gender == 1) {
                        item.thumb = item.extra.thumb || item.extra.man_thumb;
                    } else {
                        item.thumb = item.extra.thumb || item.extra.woman_thumb;
                    }
                    arr2.push(item);
                }
            })

            this.list3.forEach((item) => {
                if (item.gender == this.params.gender || item.gender == 0) {
                    if (this.params.gender == 1) {
                        item.thumb = item.extra.thumb || item.extra.man_thumb;
                    } else {
                        item.thumb = item.extra.thumb || item.extra.woman_thumb;
                    }
                    arr3.push(item);
                }
            })

            this.list4.forEach((item) => {
                if (item.gender == this.params.gender || item.gender == 0) {
                    if (this.params.gender == 1) {
                        item.thumb = item.extra.thumb || item.extra.man_thumb;
                    } else {
                        item.thumb = item.extra.thumb || item.extra.woman_thumb;
                    }
                    arr4.push(item);
                }
            })

            return [
                {
                    label: '搭配风格',
                    list: arr1,
                },
                {
                    label: '风格氛围感',
                    list: arr2,
                },
                {
                    label: '适合场合',
                    list: arr3,
                },
                {
                    label: '适合季节',
                    list: arr4,
                },
            ]
        },
        styles() {
            let ids = [];
            this.list[0].list.forEach((item) => {
                if (item.active) {
                    ids.push(item.id);
                }
            })
            return ids;
        },
        atmosphere() {
            let ids = [];
            this.list[1].list.forEach((item) => {
                if (item.active) {
                    ids.push(item.id);
                }
            })
            return ids;
        },
        occasion() {
            let ids = [];
            this.list[2].list.forEach((item) => {
                if (item.active) {
                    ids.push(item.id);
                }
            })
            return ids;
        },
        season() {
            let ids = [];
            this.list[3].list.forEach((item) => {
                if (item.active) {
                    ids.push(item.id);
                }
            })
            return ids;
        },
    },
    mounted() {
        let query = JSON.parse(this.$route.query.data);
        if (query.id) {
            this.getDetail(query.id);
        }
        this.image = localStorage.getItem('image');
        this.getAttributes();
    },
    methods: {
        back() {
            this.$router.back();
        },
        getDetail(id) {
            api.get('/ast-scheme/scheme-detail', { id }, (res) => {
                if (res.code == 0) {
                    this.params.title = res.data.title;
                    this.params.summary = res.data.summary;
                    this.params.gender = res.data.gender;
                    this.setStyle(res.data.styles,0);
                    this.setStyle(res.data.atmosphere,1);
                    this.setStyle(res.data.occasion,2);
                    this.setStyle(res.data.season,3);
                }
            })
        },
        setStyle(list,idx) {
            if (this.list[idx].list.length) {
                console.log(this.list[idx]);
                list.forEach((item) => {
                    this.list[idx].list.forEach((key) => {
                        if (item.id == key.id) {
                            this.$set(key, 'active', true);
                        }
                    })
                })
            }else{
                setTimeout(() => {
                    this.setStyle(list,idx);
                }, 200);
            }
        },
        submitScheme() {

            if (!this.params.title) {
                return this.showError('搭配标题不能为空');
            }

            if (!this.params.summary) {
                return this.showError('搭配说明不能为空');
            }

            if (!this.params.gender) {
                return this.showError('请选择适宜性别');
            }

            if (!this.styles.length) {
                return this.showError('请选择适宜风格');
            }

            if (!this.atmosphere.length) {
                return this.showError('请选择适宜氛围');
            }

            if (!this.occasion.length) {
                return this.showError('请选择适宜场景');
            }

            if (!this.season.length) {
                return this.showError('请选择适宜季节');
            }

            let query = JSON.parse(this.$route.query.data);

            let params = this.params;
            params.styles = this.styles;
            params.atmosphere = this.atmosphere;
            params.occasion = this.occasion;
            params.season = this.season;

            params.image = localStorage.getItem('image');
            params.frame = query.frame;
            params.goods = query.goods;

            if (query.id) {
                params.id = query.id;
            }

            console.log(params);

            this.deckSave(params);
        },

        deckSave(data) {

            this.$swal.fire({
                title: '提交中，请稍候...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
            })

            api.post('/ast-scheme/sync-scheme', data, (result) => {

                this.$swal.close();

                if (result.code !== 0) {
                    localStorage.removeItem('image');
                    this.$swal({
                        title: result.msg,
                        icon: 'error',
                        confirmButtonText: '好'
                    });

                    return
                }
                this.$router.push(`/ast/shop/scheme/show`)
            })
        },
        styleChange(item) {
            this.$set(item, 'active', !item.active);
        },
        getAttributes() {
            api.get('/ast-scheme/attributes', (data) => {

                if (!data.data) return;

                this.filterStyle(data.data.attributes);
            })
        },
        filterStyle(list) {
            // 风格
            let list1 = [];
            // 氛围
            let list2 = [];
            // 场合
            let list3 = [];
            // 季节
            let list4 = [];

            list.forEach((item) => {
                if (item.parent_id == 10086) {
                    list1.push(item);
                }
                if (item.parent_id == 8) {
                    list2.push(item);
                }
                if (item.parent_id == 5) {
                    list3.push(item);
                }
                if (item.parent_id == 7) {
                    list4.push(item);
                }
            })

            this.list1 = list1;
            this.list2 = list2;
            this.list3 = list3;
            this.list4 = list4;
        },
        showError(msg) {
            this.$swal({
                title: msg,
                icon: 'error',
                confirmButtonText: '好'
            });
        },
    }
}
</script>

<style lang="less" scoped>
.scheme-info {
    display: flex;

    .info-right {
        .scheme-img {
            width: 248px;
            height: 248px;
            background: #F5F5F5;
            padding: 8px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .scheme-label {
            width: 248px;
            text-align: center;
            line-height: 24px;
            color: rgba(51, 51, 51, 0.8);
            margin-top: 16px;
        }
    }

    .info-left {
        width: 840px;
        margin-right: 40px;

        .list {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            .item {
                width: fit-content;
                margin-right: 16px;
                margin-bottom: 16px;

                .bg {
                    position: relative;

                    img {
                        width: 68px;
                        height: 68px;
                        border-radius: 50%;
                    }

                    .active {
                        width: 68px;
                        height: 68px;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }

                .name {
                    color: #333333;
                    font-size: 16px;
                    text-align: center;
                    margin-top: 12px;
                }
            }
        }

        .btn-box {
            display: flex;
            align-items: center;
            margin-bottom: 32px;

            div {
                width: 92px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                font-size: 14px;
                cursor: pointer;
            }

            .cancel {
                color: #F5606D;
                border: 1px solid #F5606D;
                margin-right: 16px;
            }

            .submit {
                color: #FFFFFF;
                background: #333333;
            }
        }

        input {
            width: 90%;
            resize: none;
            border: none;
            outline: none;
            box-shadow: none;
            color: #333333;
        }

        .num {
            color: rgba(0, 0, 0, 0.25);
            font-size: 14px;
        }

        .gender {
            width: 72px;
            height: 40px;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 16px;
            color: #333333;
            background: #F7F7F7;
            cursor: pointer;

            img {
                width: 16px;
                height: 16px;
                margin-right: 4px;
            }
        }

        .active {
            color: #FFFFFF;
            background: #333333;
        }
    }
}
</style>